<template>
  <q-header
    class="bg-white header"
    reveal
    bordered
  >
    <q-toolbar>
      <q-toolbar-title class="header-title">
        <q-btn
          v-if="$q.platform.is.mobile"
          size="md"
          icon="img:/img/nav-icons/navigation-menu.svg"
          flat
          round
          class="q-mr-0_5 menu-icon"
          @click="$emit('toggleMenu')"
        />
        <link-button v-else />
        <router-link
          to="/"
          class="header-logo"
        >
          <img
            src="@/assets/logo.png"
            alt="gsk logo"
            height="38px"
          >
          <span class="text-body1">Hosting Portal</span>
        </router-link>
      </q-toolbar-title>
      <project-context-switch v-if="showContextSwitch" />
      <template v-if="!$q.platform.is.mobile">
        <hp-button
          flat
          no-caps
          label="My Tasks"
          :route="{ name: 'my-tasks' }"
          class="text-secondary text-body2 q-mr-0_5"
        >
          <hp-badge :data="tasks" />
        </hp-button>

        <hp-button
          label="My Requests"
          flat
          no-caps
          :route="{ name: 'my-requests' }"
          class="text-secondary text-body2 q-mr-1"
        >
          <hp-badge :data="requests" />
        </hp-button>
        <hp-button
          label="My Drafts"
          flat
          no-caps
          :route="{ name: 'my-drafts' }"
          class="text-secondary text-body2 q-mr-1"
        >
          <hp-badge :data="drafts" />
        </hp-button>
      </template>
      <hp-menu-button
        :labels="['Logout']"
        @logout="logout"
      >
        <user-initials slot="label" />
      </hp-menu-button>
    </q-toolbar>
  </q-header>
</template>

<script>
import UserInitials from '@/modules/core/components/userInitials/UserInitials.vue';
import LinkButton from '@/componentLibrary/linkButton/LinkButton.vue';
import HpMenuButton from '@/componentLibrary/menuButton/MenuButton.vue';
import { getFeatureFlagsStore } from '@/modules/core/mixins';
import ProjectContextSwitch from '@/modules/myProjects/components/projectContextSwitch/ProjectContextSwitch';
import * as msalService from '../../services/msalService';
import { getActivitiesCount } from '@/modules/core/services/taskService';
import { getDraftCount } from '@/modules/core/services/draftService';
import HpBadge from '@/componentLibrary/badge/Badge.vue';
import HpButton from '@/componentLibrary/button/Button.vue';

export default {
  name: 'TopBar',
  components: {
    HpMenuButton,
    LinkButton,
    UserInitials,
    ProjectContextSwitch,
    HpBadge,
    HpButton,
  },
  mixins: [getFeatureFlagsStore],
  data() {
    return {
      tasks: 0,
      requests: 0,
      drafts: 0,
      help: false,
    };
  },
  computed: {
    showContextSwitch() {
      return this.getFeatureFlagByKey('MY_PROJECTS_OVERVIEW');
    },
  },
  async created() {
    if (!this.$q.platform.is.mobile) {
      try {
        const [activities, draftCount] = await Promise.allSettled([getActivitiesCount(), getDraftCount()]);
        this.tasks = activities.status === 'fulfilled' ? activities.value.tasks : 0;
        this.requests = activities.status === 'fulfilled' ? activities.value.requests : 0;
        this.drafts = draftCount.status === 'fulfilled' ? draftCount.value : 0;
      } catch (e) {
        // Kept empty to silently suppress it.
      }
    }
  },
  methods: {
    logout() {
      this.$appInsights.clearAuthenticatedUserContext();
      msalService.logout(this.$msalInstance);
    },
  },
};
</script>

<style lang="scss" scoped>

:deep(a:link) {
  text-decoration: none;
}
:deep(.q-toolbar) {
  padding-left: 2px;
}

.header {
  height: 72px;
  padding-top: 10px;

  &-title, &-logo {
    display: flex;
    align-items: center;
  }
}

.menu {
  position: absolute;
  margin-top: 0px;
  right: 0;
}
.menu-icon {
  filter: invert(30%) sepia(10%) saturate(856%) hue-rotate(7deg) brightness(89%) contrast(84%);
}
</style>
