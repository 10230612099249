<template>
  <div class="inline tooltip-component">
    <q-icon
      v-if="helpertextcontent || $slots.helpertextcontent"
      data-test-id="tooltipButton"
      size="1.2rem"
      name="help_outline"
      class="help-button cursor-pointer"
      @click="showTooltip(!hasTooltip)"
    />

    <div
      v-if="hasTooltip"
      class="chevron"
    />

    <div
      v-if="hasTooltip"
      class="anchor"
    >
      <div
        v-if="hasTooltip"
        ref="tooltip"
        class="tooltip"
      >
        <div class="tooltip-container">
          <div class="tooltip-heading">
            {{ label }}
          </div>
          <div class="text-white">
            <q-icon
              size="1.2rem"
              class="cursor-pointer"
              name="close"
              color="#fff"
              @click="showTooltip(false)"
            />
          </div>
        </div>
        <div class="tooltip-content">
          <slot
            v-if="!helpertextcontent"
            name="helpertextcontent"
          />
          {{ helpertextcontent }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nmae: 'HpTooltip',
  props: {
    helpertextcontent: {
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      hasTooltip: false,
    };
  },
  beforeDestroy() {
    // remove listener so there is no leak.
    if (this.hasTooltip) this.$eventBus.$off('controlFocus', this.listener);
  },
  methods: {
    async adjustScrollPosition() {
      await this.$nextTick();
      const tip = this.$refs.tooltip;
      // will all exist when outside of unit testing.
      if (tip && tip.getBoundingClientRect && tip.scrollIntoView && tip.getBoundingClientRect().top < 65) {
        tip.scrollIntoView({ behavior: 'smooth' });
      }
    },
    listener({ label }) {
      // hide this tooltip if another control has been made active
      if (label !== this.label && this.hasTooltip) this.showTooltip(false);
    },
    showTooltip(visible) {
      this.hasTooltip = visible;

      if (!visible) {
        this.$eventBus.$off('controlFocus', this.listener);
        return;
      }

      this.$eventBus.$emit('controlFocus', { label: this.label });
      this.$eventBus.$on('controlFocus', this.listener);
      this.adjustScrollPosition();
    },
  },
};
</script>

<style lang="scss">
.tooltip-component {
  &.inline {
    display: inline;
  }

  .help-button {
    position: relative;
  }

  .chevron {
    width: 0;
    border-top: 13px solid $color-secondary;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    position: relative;
    display: inline-block;
    right: 22.5px;
    bottom: 16px;
    z-index: 3;
  }

  .anchor {
    position: relative;

    .tooltip {
      position: absolute;
      background-color: $color-secondary;
      color: white !important;
      z-index: 2;
      bottom: 34px;
      padding: .5rem .5rem 1rem 1rem;
      border-radius: .4rem;
      border: 1px solid $color-surface-medium;
      width: 75%;
      margin-left: 20px;

      .tooltip-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: .5rem;

        .tooltip-heading {
          font-weight: 500;
          position: relative;
          top: 3px;
        }

        .text-white i.q-icon {
          fill: white !important;
          color: white !important;
        }
      }

      .tooltip-content {
        padding-right: .5rem;

        a {
          color: white !important;
          text-decoration: underline;
          text-underline-offset: .1rem;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
