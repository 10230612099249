<template>
  <div class="org-selector">
    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="lineOfBusiness"
      :rules="{ required: true }"
    >
      <hp-select
        data-test-id="lineOfBusiness"
        :value="value.lineOfBusiness"
        :label="constants.lineOfBusiness.label"
        :helpertextcontent="constants.lineOfBusiness.helperText"
        :options="enabledLinesOfBusiness"
        :validationmessage="errors[0]"
        required
        :disabled="disabled"
        @input="linesOfBusinessUpdated"
        @update-display-value="$emit('update-display-value', { [constants.lineOfBusiness.label]: $event })"
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="businessUnit"
      :rules="{ required: businessUnits && !!businessUnits.length }"
    >
      <hp-select
        data-test-id="businessUnits"
        :value="value.businessUnit"
        :label="constants.businessUnit.label"
        :helpertextcontent="constants.businessUnit.helperText"
        :options="businessUnits"
        :validationmessage="errors[0]"
        required
        :disabled="!businessUnits || !businessUnits.length || disabled"
        @input="businessUnitUpdated"
        @update-display-value="$emit('update-display-value', { [constants.businessUnit.label]: $event })"
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="businessFunction"
      :rules="{ required: businessFunctions && !!businessFunctions.length }"
    >
      <hp-select
        data-test-id="businessFunctions"
        :value="value.businessFunction"
        :label="constants.businessFunction.label"
        :options="businessFunctions"
        :validationmessage="errors[0]"
        required
        :disabled="!businessFunctions || !businessFunctions.length || disabled"
        @input="businessFunctionUpdated"
        @update-display-value="$emit('update-display-value', { [constants.businessFunction.label]: $event })"
      />
    </validation-provider>
  </div>
</template>

<script>
import HpSelect from '@/componentLibrary/select/Select.vue';
import { ValidationProvider } from 'vee-validate';
import referenceDataService from '@/modules/core/services/referenceDataService';
import constants from './constants';
import '@/modules/core/validators/formValidators';

export default {
  name: 'OrgSelector',
  components: {
    HpSelect,
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    requestType: {
      type: String,
    },
    techEnvironment: {
      type: String,
    },
    tenant: {
      type: String,
    },
    value: {
      default: () => ({
        businessUnit: null,
        businessFunction: null,
        lineOfBusiness: null,
      }),
      type: Object,
    },
    disabledLinesOfBusiness: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      businessUnits: [],
      businessFunctions: [],
      linesOfBusiness: [],
    };
  },
  computed: {
    constants() {
      return constants;
    },
    enabledLinesOfBusiness() {
      return this.disabledLinesOfBusiness.length && this.linesOfBusiness ?
        this.linesOfBusiness.filter(r => !this.disabledLinesOfBusiness.includes(r.value)) :
        this.linesOfBusiness;
    },
  },
  watch: {
    async techEnvironment() {
      this.initialize();
    },
  },
  async created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        if (this.disabled && !this.value.lineOfBusiness) return;
        this.linesOfBusiness = await referenceDataService.getLinesOfBusiness(this.requestType, this.tenant, this.techEnvironment);
        if (this.value.lineOfBusiness && !this.linesOfBusiness.some(r => r.value === this.value.lineOfBusiness)) {
          await this.linesOfBusinessUpdated(null);
        } else {
          if (this.value.lineOfBusiness) await this.linesOfBusinessUpdated(this.value.lineOfBusiness);
          if (this.value.businessFunction) await this.businessFunctionUpdated(this.value.businessFunction);
          if (this.value.businessUnit) await this.businessUnitUpdated(this.value.businessUnit);
        }
      } catch (e) {
        this.$emit('componentFailed', e);
      }
    },
    async linesOfBusinessUpdated(val) {
      if (!val) {
        this.businessUnits = [];
        this.businessFunctions = [];
        return;
      }

      this.businessUnits = await referenceDataService.getBusinessUnits(val, this.requestType, this.tenant);

      if (this.businessUnits.some(r => r.value === this.value.businessUnit)) {
        return; // must be pre population so no emit.
      }

      const model = {
        lineOfBusiness: val,
        businessUnit: null,
        businessFunction: null,
      };

      this.$emit('input', model);
    },
    async businessUnitUpdated(val) {
      if (!val) {
        this.businessFunctions = [];
        return;
      }

      this.businessFunctions = await referenceDataService.getBusinessFunctions(this.value.lineOfBusiness, val, this.requestType, this.tenant);

      if (this.businessFunctions.some(r => r.value === this.value.businessFunction)) {
        return; // must be pre population so no emit.
      }

      const model = {
        ...this.value,
        businessUnit: val,
        businessFunction: null,
      };

      this.$emit('input', model);
    },
    async businessFunctionUpdated(val) {
      const model = {
        ...this.value,
        businessFunction: val,
      };

      this.$emit('input', model);
    },
    updateDisplay(type, newVal) {
      this.$emit('update-display-value', { [type]: newVal });
    },
  },
};
</script>
<style>
.org-selector {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
